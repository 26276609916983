import React from "react";
import Helmet from "react-helmet";
import Navbar from "./navbar.js";
import Footer from "./footer";

import "tachyons";
import "../../styles/custom.tachyons.css";
import layoutStyles from "./layout.module.css";

export default (props) => (
  <React.Fragment>
    {/* <div className={layoutStyles.container}> */}
      {/* <div className={layoutStyles.content}> */}
        <Helmet>
          <body className="bg-near-white mid-gray" />
        </Helmet>
        <Navbar />
        {props.children}
      {/* </div> */}
      <Footer />
    {/* </div> */}
  </React.Fragment>
)
