import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import {
  FaFacebookSquare,
  FaTwitter,
  FaInstagram,
  FaWhatsapp,
  FaYoutube,
} from "react-icons/fa";

import "tachyons";

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          informationLinks {
            to
            name
          }
          governmentLinks {
            to
            name
          }
          galleryLinks {
            to
            name
          }
          documentLinks {
            to
            name
          }
          aboutLinks {
            to
            name
          }
          pageInformationTitle
          pageGovernmentTitle
          pageGalleryTitle
          pageDocumentTitle
          pageAboutTitle
        }
      }
    }
  `);

  return (
    <footer className="pa2 bg-dark-gray near-white pt5 pb2 ph6">
      <div className="flex flex-wrap w-100 mw9 fl-w100">
        <div className="w-30 mb4">
          <span className="display f4 fw3 ttu tracked">Tewai Hara</span>

          <div className="w-100 fl mt4">
            <a
              className="link near-white hover-silver dib mr3 tc"
              href="https://facebook.com"
              title="Facebook"
            >
              <FaFacebookSquare size={28} />
            </a>
            <a
              className="link near-white hover-silver dib mh3 tc"
              href=""
              title=""
            >
              <FaTwitter size={28} />
            </a>
            <a
              className="link near-white hover-silver dib mh3 tc"
              href=""
              title=""
            >
              <FaInstagram size={28} />
            </a>
            <a
              className="link near-white hover-silver dib mh3 tc"
              href=""
              title=""
            >
              <FaYoutube size={28} />
            </a>
            <a
              className="link near-white hover-silver dib mh3 tc"
              href=""
              title=""
            >
              <FaWhatsapp size={28} />
            </a>
          </div>
        </div>

        <article className="fl w-50 dib-ns w-auto-ns mr4-m mr5-l pr2 pr0-ns">
          <span className="f6 f6-l fw7 db mb3 ttu">
            {data.site.siteMetadata.pageInformationTitle}
          </span>
          {data.site.siteMetadata.informationLinks.map((navLink) => (
            <Link
              className="near-white sans-serif f6 tracked pv1 db no-underline hover-silver"
              to={navLink.to}
            >
              {navLink.name}
            </Link>
          ))}
        </article>

        <article className="fl w-50 dib-ns w-auto-ns mr4-m mr5-l pr2 pr0-ns">
          <span className="f6 f6-l fw7 db mb3 ttu">
            {data.site.siteMetadata.pageDocumentTitle}
          </span>
          {data.site.siteMetadata.documentLinks.map((navLink) => (
            <Link
              key={navLink.name}
              className="near-white sans-serif f6 tracked pv1 db no-underline hover-silver"
              to={navLink.to}
            >
              {navLink.name}
            </Link>
          ))}
        </article>

        <article className="fl w-50 dib-ns w-auto-ns mr4-m mr5-l pr2 pr0-ns">
          <span className="f6 f6-l fw7 db mb3 ttu">
            {data.site.siteMetadata.pageGalleryTitle}
          </span>
          {data.site.siteMetadata.galleryLinks.map((navLink) => (
            <Link
              key={navLink.name}
              className="near-white sans-serif f6 tracked pv1 db no-underline hover-silver"
              to={navLink.to}
            >
              {navLink.name}
            </Link>
          ))}
        </article>

        <article className="fl w-50 dib-ns w-auto-ns mr4-m mr5-l pr2 pr0-ns">
          <span className="f6 f6-l fw7 db mb3 ttu">
            {data.site.siteMetadata.pageGovernmentTitle}
          </span>
          {data.site.siteMetadata.governmentLinks.map((navLink) => (
            <Link
              key={navLink.name}
              className="near-white sans-serif f6 tracked pv1 db no-underline hover-silver"
              to={navLink.to}
            >
              {navLink.name}
            </Link>
          ))}
        </article>

        <article className="fl w-50 dib-ns w-auto-ns mr4-m mr5-l pr2 pr0-ns">
          <span className="f6 f6-l fw7 db mb3 ttu">
            {data.site.siteMetadata.pageAboutTitle}
          </span>
          {data.site.siteMetadata.aboutLinks.map((navLink) => (
            <Link
              key={navLink.name}
              className="near-white sans-serif f6 tracked pv1 db no-underline hover-silver"
              to={navLink.to}
            >
              {navLink.name}
            </Link>
          ))}
        </article>
      </div>

      {/* <div className='flex flex-wrap w-100 mw9 mb5'>
        <article className='fl w-50 dib-ns w-auto-ns mr4-m mr5-l pr2 pr0-ns'>
          <h4 className='f5 f4-l fw6'>SF</h4>
          <Link to='/blog' className='near-white sans-serif f6 tracked pv1 db no-underline'>Berita</Link>
          <Link to='/rss.xml' className='near-white sans-serif f6 tracked pv1 db no-underline'>Agenda</Link>
          <Link to='/rss.xml' className='near-white sans-serif f6 tracked pv1 db no-underline'>Pengumuman</Link>
          <Link to='/rss.xml' className='near-white sans-serif f6 tracked pv1 db no-underline'>Artikel</Link>
        </article>
      </div> */}

      <div className="w-100 mw9 silver sans-serif f6 mt5">
        <div className="w-100 bb b--mid-gray mv3" />
        <p>Tewai Hara, © 2020</p>
      </div>
    </footer>
  );
};

export default Footer;
